
import { defineComponent, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-sales-orders-modal",
  props: {
    rangeSize: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form", "rangeSize:update"],
  setup(props, context) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const rangeSizeChangeModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const formData = ref({
      size: "",
    });

    const rules = ref({});

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          const value = formData.value.size;
          context.emit("update-list", value);
          handleDiscard();
        } else {
          //   showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(rangeSizeChangeModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      context.emit("reset-form");
    };

    const updateList = () => {
      handleDiscard();
      context.emit("update-list");
    };

    const initData = (data) => {
      formData.value.size = data;
    };

    onMounted(() => {
      // modalShowListener(rangeSizeChangeModalRef.value, () => {
      //   init();
      // });
      modalHideListener(rangeSizeChangeModalRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      loading,
      formData,
      rules,
      formRef,
      rangeSizeChangeModalRef,
      submitButton,
      initData,
      submit,
      handleDiscard,
      resetForm,
      updateList,
    };
  },
});

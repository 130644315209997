import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_draft_orders_update_shipping_fee",
  ref: "updateShippingFeeRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-400px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_draft_orders_update_shipping_fee_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-12" }
const _hoisted_11 = { class: "form-group mb-7" }
const _hoisted_12 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_13 = { class: "modal-footer align-items-center justify-content-end max-sm-justify-content-center" }
const _hoisted_14 = { class: "svg-icon svg-icon-3" }
const _hoisted_15 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          rules: _ctx.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("orderRequest.shippingChargeTitle")), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.t("orderRequest.amount")), 1),
                    _createVNode(_component_el_form_item, { prop: "amount" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          class: "w-100 text-start",
                          modelValue: _ctx.formData.amount,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.amount) = $event)),
                          precision: 2,
                          step: 0.01,
                          min: 0,
                          controls: false
                        }, null, 8, ["modelValue", "step"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-light me-2",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleReset && _ctx.handleReset(...args)))
              }, [
                _createElementVNode("span", _hoisted_14, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr059.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.t("orderRequest.reset")), 1)
              ]),
              _createElementVNode("button", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen005.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.t("common.apply")), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ], 512))
}